const colors = require("./colors.json");

/**
 * Do not remove any keys!!
 * The shape of this file is to remain the same so that all options are know.
 *
 * If a key does not have a value, set the value to undefined.
 */
module.exports = {
  communityName: "Abington Heights Townhomes",
  gtagId: "G-Y9YQ063H5Y",
  logo: {
    src: "https://cdn.maxxpm.com/abingtonheights/ah_logo.svg",
    alt: "Abington Heights Townhomes Logo",
  },
  colors,
  nav: {
    bannerSubtitle: undefined,
    bannerTitle: undefined,
    bannerCTA: undefined,
  },
  footer: {
    address: "3706 S 1900 W",
    cityStateZip: "Roy, UT 84067",
    phone: "(385) 287-0208",
    copyrightYear: "2023",
    logo: "https://cdn.maxxpm.com/abingtonheights/ah_full_white.svg",
  },
  listMarkerSrc: "https://cdn.maxxpm.com/abingtonheights/ah_marker.svg",
  hasCalendlyPopup: true,
  calendlyLink: "https://calendly.com/abington-fdy/30min",
  home: {
    banner: {
      image:
        "https://cdn.maxxpm.com/abingtonheights/Abington Website Photos/33-web-or-mls-3712 S 1940 W-30.jpg",
      video: undefined,
      title: "Luxury Townhomes",
      subtitle: "We Can't Wait To Welcome You Home!",
      openHouse: {
        start: new Date("2024-11-02T10:00:00.000-06:00"),
        end: new Date("2024-11-02T14:00:00.000-06:00"),
        image: "https://cdn.maxxpm.com/abingtonheights/Abington Website Photos/1.jpg"
      }
    },
    aboutUs: {
      paragraphs: [
        "Abington Heights Townhomes in Roy is a vibrant community in Weber County, Utah. Located just six miles southwest of Ogden, residents in this area enjoy a wide range of amenities throughout the year. Roy city offers a modern recreation complex with basketball courts, fitness rooms, and a swimming pool, as well as the fascinating Hill Airforce Base and Roy Historical Museums. In addition to living in a prime location near popular restaurants, recreation sites, and shopping centers, Abington Heights residents have easy access to the outdoor recreational attractions of northern Utah, including the Eagle Lake Golf Course, the Roy Aquatic Center, and renowned ski resorts like Snowbasin and Nordic Valley, all within a 40-minute drive.",
        "Abington Heights truly has something for everyone. Comfortable rooms, ample closets, brand-new stainless-steel appliances, 2 car garage, and the convenience of smart home enabled features, all combine to make living at Abington Heights Townhomes a luxurious experience. Embrace the perfect blend of comfort, convenience, and adventure in your ideal home.",
      ],
      image: {
        src: "https://cdn.maxxpm.com/abingtonheights/Abington%20Website%20Photos/4-web-or-mls-3712%20S%201940%20W-1.jpg",
        alt: "Abington Heights bedroom",
      },
    },
    floorplanTitle: "Explore Our Floor Plan",
    floorplans: [
      {
        hasIcons: true,
        icons: [
          {
            text: "3",
            icon: "fas fa-bed",
          },
          {
            text: "2.5",
            icon: "fas fa-bath",
          },
          {
            text: "2",
            icon: "fas fa-warehouse",
          },
        ],
        /** Not required */
        name: undefined,
        image: {
          src: "https://cdn.maxxpm.com/abingtonheights/Abington Website Photos/AH FP.png",
          alt: "Abington Heights floor plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=KGFDmaXDmZx",
          title: "Abington Heights 3D Walkthrough",
        },
      },
    ],
    midContainerImage:
      "https://cdn.maxxpm.com/stockphotos/mother and children dancing.jpg",
    imgRow: [
      {
        src: "https://cdn.maxxpm.com/abingtonheights/Abington%20Website%20Photos/23-web-or-mls-3712%20S%201940%20W-20.jpg",
        alt: "Abington Heights bedroom",
      },
      {
        src: "https://cdn.maxxpm.com/abingtonheights/Abington%20Website%20Photos/37-web-or-mls-3712%20S%201940%20W-34.jpg",
        alt: "Abington Heights kitchen",
      },
      {
        src: "https://cdn.maxxpm.com/abingtonheights/Abington%20Website%20Photos/34-web-or-mls-3712%20S%201940%20W-31.jpg",
        alt: "Abington Heights living room",
      },
    ],
    animalPolicy: {
      img: {
        src: "https://cdn.maxxpm.com/stockphotos/boy+kissing+dog.jpg",
        alt: "Animal policy stock photo",
      },
      policies: [
        "No more than 2 animals are allowed per property",
        "Cats/Dogs: $250/animal*",
        "$50/animal/month for animals under 50lbs*",
        "$75/animal/month for animals 50lbs+*",
        "One-time $75/animal DNA testing fee*",
        "Caged animals: $25/animal/month*",
        "Fish tanks are not permitted",
        "Rabbits and rodents of any kind are not permitted",
      ],
    },
    breeds: [
      "Pit Bulls & Staffordshire Terriers",
      "Doberman Pinschers",
      "Rottweilers",
      "Chows",
      "Great Danes",
      "Presa Canarios/ Mastiffs",
      "Akitas",
      "Alaskan Malamutes",
      "Huskies",
      "German Shepherds",
      "Shar Peis",
      "Wolf-Hybrids",
    ],
  },
  availability: {
    banner: {
      image: "https://cdn.maxxpm.com/abingtonheights/floor_plan.png",
      video: undefined,
      title: "Available Units",
      subtitle:
        "Unit availability is updated often from Monday-Friday during business hours. Evenings and weekends may not reflect accurate availability dates.",
    },
    hasWaitingList: true,
    waitingListJotformId: "232016066484150",
    propertyGroup: "LEADS-AH",
  },
  faq: {
    banner: {
      image:
        "https://cdn.maxxpm.com/lagunafarms/logoandbanners/girls+on+bed+with+dog.jpg",
    },
  },
};
